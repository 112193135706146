import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import unityLogo from "assets/images/unity.png";
import dojoLogo from "assets/images/dojo.png";
import starknetLogo from "assets/images/starknet.png";

import style from "./style.module.scss";
import { openLink } from "utils";

const Introduction = () => {
  const [marqueeStart, setMarqueeStart] = useState(false);

  useEffect(() => {
    window.setMarqueeStart = setMarqueeStart;
  }, []);

  return (
    <div
      id="introduction"
      className={twMerge(
        style.introduction_main,
        "reverse sticky top-0 flex h-screen w-full flex-col",
        "smallDesktop:items-center smallDesktop:justify-center"
      )}
    >
      <div
        className={twMerge(
          "relative mt-[148px] px-4",
          "smallDesktop:-mt-16 smallDesktop:max-w-[930px] smallDesktop:px-0",
          window.innerHeight < 840 && "smallDesktop:mt-8"
        )}
      >
        <div
          className={twMerge(
            "absolute -top-[60px] left-1/2 flex h-[36px] w-[calc(100%-32px)] -translate-x-1/2 cursor-pointer rounded-sm border border-primary600",
            "smallDesktop:-top-[100px] smallDesktop:w-[330px]",
            window.innerHeight < 840 && "smallDesktop:-top-[56px]"
          )}
          onClick={() =>
            openLink(
              "https://blobarena.xyz"
            )
          }
        >
          <Marquee
            className="flex-1 bg-white bg-opacity-[3%] font-sora text-sm font-normal text-white"
            play={marqueeStart}
            delay={0.5}
          >
            <span className="pl-10">
            Blob Arena is in Early Access! Play on blobarena.xyz
            </span>
          </Marquee>
          <div className="flex w-9 items-center justify-center bg-primary600">
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-lg text-white"
            />
          </div>
        </div>
        <h1
          className={twMerge(
            "max-w-[600px] font-avara text-5xl font-extrabold text-white",
            "smallDesktop:max-w-none smallDesktop:text-center smallDesktop:text-7xl"
          )}
        >
          <span className="relative overflow-hidden whitespace-nowrap">
            <div
              id="introduction-title-background"
              className={twMerge(
                style.introduction_title_background,
                "absolute -left-1 top-0 h-full w-full",
                "tablet:-left-2"
              )}
            >
              <div
                className="absolute h-[80%] w-full bg-primary600"
                style={{
                  transform:
                    "perspective(12px) rotateX(-0.8deg) rotateY(-0.1deg)",
                }}
              />
            </div>
            <span className="relative">Pioneering</span>
          </span>
          &nbsp;
          <span className={style.forceLineBreak}>
            <br />
          </span>
          the future of Gaming
        </h1>
      </div>
      <p
        className={twMerge(
          "text-sora mt-4 max-w-[658px] px-4 text-base font-light text-grayCool300",
          "smallDesktop:mt-6 smallDesktop:px-0 smallDesktop:text-center smallDesktop:text-3xl",
          window.innerHeight < 840 && "smallDesktop:mt-2"
        )}
      >
        The Lair where the gaming revolution comes to life
      </p>
      <div
        className={twMerge(
          "mt-6 flex gap-2 self-center",
          "smallDesktop:mt-12",
          window.innerHeight < 840 && "smallDesktop:mt-6"
        )}
      >
        <img
          src={unityLogo}
          alt="unity"
          className={twMerge("h-10", "smallDesktop:h-16")}
        />
        <img
          src={dojoLogo}
          alt="dojo"
          className={twMerge("h-10", "smallDesktop:h-16")}
        />
        <img
          src={starknetLogo}
          alt="starknet"
          className={twMerge("h-10", "smallDesktop:h-16")}
        />
      </div>
    </div>
  );
};

export default Introduction;
